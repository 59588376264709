<template>
  <div
    class="align-start text-left overflow-hidden transform transition-all w-full"
  >
    <div
      class="mb-4 bg-white w-full px-4 pt-4 pb-4 sm:p-6 sm:pb-4 border border-gray-200 rounded-lg overflow-hidden"
    >
      <div>
        <span class="text-sm mr-2">paciente:</span>
        <span v-if="block" class="text-md text-gray-700 font-medium capitalize"
          >{{ block.reservation.purchase.user.names }}
          {{ block.reservation.purchase.user.lastnames }}</span
        >
      </div>
      <div>
        <span class="text-sm mr-2">dirección de correo:</span>
        <span v-if="block" class="text-md text-gray-700 font-medium">{{
          block.reservation.purchase.user.email
        }}</span>
      </div>
      <div>
        <span class="text-sm mr-2">previsión de salud:</span>
        <span v-if="block" class="text-md text-gray-700 font-medium">{{
          block.reservation.purchase.user.patient.health
        }}</span>
      </div>
      <div>
        <span class="text-sm mr-2">rut:</span>
        <span v-if="block" class="text-md text-gray-700 font-medium">{{
          block.reservation.purchase.user.patient.rut
        }}</span>
      </div>
    </div>
    <!-- notification -->
    <transition name="fade" mode="out-in">
      <div v-if="success" class="w-full flex justify-end items-center">
        <div
          class="mb-4 w-full max-w-sm overflow-hidden rounded-lg bg-white border border-gray-200"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <!-- Heroicon name: outline/check-circle -->
                <svg
                  class="h-6 w-6 text-green-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900">
                  Formulario almacenado!
                </p>
              </div>
              <div class="ml-4 flex flex-shrink-0">
                <button
                  @click="
                    () => {
                      success = '';
                    }
                  "
                  type="button"
                  class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span class="sr-only">Close</span>
                  <!-- Heroicon name: mini/x-mark -->
                  <svg
                    class="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade" mode="out-in">
      <div v-if="error" class="w-full flex justify-end items-center">
        <div
          class="mb-4 w-full max-w-sm overflow-hidden rounded-lg bg-white border border-gray-200"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <!-- Heroicon name: outline/check-circle -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-6 w-6 text-red-400"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900">
                  {{ error }}
                </p>
              </div>
              <div class="ml-4 flex flex-shrink-0">
                <button
                  @click="
                    () => {
                      error = '';
                    }
                  "
                  type="button"
                  class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span class="sr-only">Close</span>
                  <!-- Heroicon name: mini/x-mark -->
                  <svg
                    class="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- end notification -->
    <!-- FORM -->
    <div
      class="bg-white w-full border border-gray-200 rounded-lg overflow-hidden"
    >
      <!-- title -->
      <div class="w-full flex flex-col justify-center items-start">
        <div class="w-full px-4 pt-4 sm:px-6 sm:pt-6">
          <h3 class="text-lg font-medium leading-6 text-gray-900">
            Formulario previo a la primera consulta
          </h3>
          <p
            v-if="this.user.roles.find(e => e.name === 'doctor')"
            class="mt-1 text-left max-w-2xl text-sm text-gray-500"
          >
            Crea o edita los datos del formulario.
          </p>
        </div>
        <form
          @submit.prevent="handleSubmit"
          class="inline-block align-middle transform transition-all w-full"
        >
          <div
            class="w-full grid grid-cols-6 gap-6 mt-4 px-4 pb-4 sm:px-6 sm:pb-6"
          >
            <!-- marital_status -->
            <div
              class="col-span-6 mb-4 md:col-span-3 flex flex-col justify-center items-start"
            >
              <label
                for="marital_status"
                class="block text-sm font-medium text-gray-700"
                >Estado Civil</label
              >
              <div class="mt-1 w-full">
                <select
                  name="marital_status"
                  :disabled="!this.user.roles.find(e => e.name === 'doctor')"
                  autocomplete="marital_status"
                  v-model="form.marital_status"
                  :class="
                    submitted && $v.form.marital_status.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                  "
                  class="block border w-full rounded-md shadow-sm sm:text-sm"
                >
                  <option selected disabled :value="null">Seleccionar</option>
                  <option
                    v-for="(status, statusIndex) in marital_status"
                    :key="statusIndex"
                    >{{ status }}</option
                  >
                </select>
              </div>
              <div
                v-if="submitted && !$v.form.marital_status.required"
                class="text-sm text-red-600"
              >
                El Estado Civil es obligatorio
              </div>
            </div>
            <!-- living -->
            <div
              class="col-span-6 mb-4 md:col-span-3 flex flex-col justify-center items-start"
            >
              <label
                for="living"
                class="block text-sm font-medium text-gray-700"
                >¿Con quién vive?</label
              >
              <div class="mt-1 w-full">
                <select
                  name="living"
                  :disabled="!this.user.roles.find(e => e.name === 'doctor')"
                  autocomplete="living"
                  v-model="form.living"
                  :class="
                    submitted && $v.form.living.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                  "
                  class="block border w-full rounded-md shadow-sm sm:text-sm"
                >
                  <option selected disabled :value="null">Seleccionar</option>
                  <option
                    v-for="(element, elementIndex) in living"
                    :key="elementIndex"
                    >{{ element }}</option
                  >
                </select>
              </div>
              <div
                v-if="submitted && !$v.form.living.required"
                class="text-sm text-red-600"
              >
                Esta información es obligatoria
              </div>
            </div>
            <!-- educational_level -->
            <div
              class="col-span-6 mb-4 md:col-span-3 flex flex-col justify-center items-start"
            >
              <label
                for="educational_level"
                class="block text-sm font-medium text-gray-700"
                >Nivel de estudios</label
              >
              <div class="mt-1 w-full">
                <select
                  name="educational_level"
                  :disabled="!this.user.roles.find(e => e.name === 'doctor')"
                  autocomplete="educational_level"
                  v-model="form.educational_level"
                  :class="
                    submitted && $v.form.educational_level.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                  "
                  class="block border w-full rounded-md shadow-sm sm:text-sm"
                >
                  <option selected disabled :value="null">Seleccionar</option>
                  <option
                    v-for="(element, elementIndex) in educational_level"
                    :key="elementIndex"
                    >{{ element }}</option
                  >
                </select>
              </div>
              <div
                v-if="submitted && !$v.form.marital_status.required"
                class="text-sm text-red-600"
              >
                El nivel educacional es obligatorio
              </div>
            </div>
            <!-- job_occupation -->
            <div
              class="col-span-6 mb-4 md:col-span-3 flex flex-col justify-center items-start"
            >
              <label
                for="job_occupation"
                class="block text-sm font-medium text-gray-700"
                >Ocupación laboral</label
              >
              <div class="mt-1 w-full">
                <input
                  v-model="form.job_occupation"
                  :disabled="!this.user.roles.find(e => e.name === 'doctor')"
                  type="text"
                  name="job_occupation"
                  id="job_occupation"
                  :class="
                    submitted && $v.form.job_occupation.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                  "
                  class="block border w-full rounded-md shadow-sm sm:text-sm"
                  placeholder="Escribe aquí"
                />
              </div>
              <div
                v-if="submitted && !$v.form.job_occupation.required"
                class="text-sm text-red-600"
              >
                La ocupación es obligatoria
              </div>
            </div>
            <!-- diseases -->
            <div
              class="col-span-6 mb-4 md:col-span-3 flex flex-col justify-center items-start"
            >
              <label
                for="diseases"
                class="block text-sm font-medium text-gray-700"
                >Enfermedades</label
              >
              <div class="mt-1 w-full">
                <div class="w-full flex flex-col">
                  <div
                    v-for="(element, elementIndex) in diseases"
                    :key="elementIndex"
                    class="w-full flex justify-between my-2 items-center"
                  >
                    <span class="text-sm text-gray-700 text-left">{{
                      element
                    }}</span>
                    <div class="flex">
                      <div class="flex items-center mr-4">
                        <button
                          @click="
                            () => {
                              temp = form.diseases.split(';');
                              temp.push(element);
                              form.diseases = temp.join(';');
                              diseases_null = diseases_null.filter(
                                e => e !== element
                              );
                            }
                          "
                          type="button"
                          :class="
                            form.diseases.split(';').includes(element)
                              ? 'bg-green-600'
                              : 'bg-gray-200'
                          "
                          class="h-8 w-8 rounded-md mr-2 flex justify-center items-center"
                        >
                          <svg
                            v-if="form.diseases.split(';').includes(element)"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-4 h-4 text-white"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M4.5 12.75l6 6 9-13.5"
                            />
                          </svg>
                        </button>
                        <span class="text-gray-600">Si</span>
                      </div>
                      <div class="flex items-center mr-4">
                        <button
                          @click="
                            () => {
                              form.diseases = form.diseases
                                .split(';')
                                .filter(e => e !== element)
                                .join(';');
                              diseases_null = diseases_null.filter(
                                e => e !== element
                              );
                            }
                          "
                          type="button"
                          :class="
                            !form.diseases.split(';').includes(element) &&
                            !diseases_null.includes(element)
                              ? 'bg-green-600'
                              : 'bg-gray-200'
                          "
                          class="h-8 w-8 rounded-md mr-2 flex justify-center items-center"
                        >
                          <svg
                            v-if="
                              !form.diseases.split(';').includes(element) &&
                                !diseases_null.includes(element)
                            "
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-4 h-4 text-white"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M4.5 12.75l6 6 9-13.5"
                            />
                          </svg>
                        </button>
                        <span class="text-gray-600">No</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="submitted && !$v.form.diseases.required"
                class="text-sm text-red-600"
              >
                Esta información es obligatoria
              </div>
            </div>
            <!-- specialty -->
            <div
              class="col-span-6 mb-4 md:col-span-3 flex flex-col justify-center items-start"
            >
              <label
                for="specialty"
                class="block text-sm font-medium text-gray-700"
                >Especificar nombre de médico que lo deriva y su
                especialidad</label
              >
              <div class="mt-1 w-full">
                <input
                  v-model="form.specialty"
                  :disabled="!this.user.roles.find(e => e.name === 'doctor')"
                  type="text"
                  name="specialty"
                  id="specialty"
                  :class="
                    submitted && $v.form.specialty.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                  "
                  class="block border w-full rounded-md shadow-sm sm:text-sm"
                  placeholder="Escribe aquí"
                />
              </div>
              <div
                v-if="submitted && !$v.form.specialty.required"
                class="text-sm text-red-600"
              >
                La especialidad es obligatoria
              </div>
            </div>
          </div>
          <div
            v-if="this.user.roles.find(e => e.name === 'doctor')"
            class="bg-gray-100 px-4 py-3 text-right sm:px-6"
          >
            <button
              type="submit"
              class="inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
            >
              Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PatientService from '@/services/patientService';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'Preconsulta',
  components: {},
  props: {
    id: {
      type: [Number, String],
      default: null,
      required: false,
    },
    block: {
      type: Object,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      marital_status: [
        'Soltero',
        'Casado/Auc',
        'Separado/Divorciado',
        'Viudo(a)',
      ],
      living: [
        'Familia Origen',
        'Pareja',
        'Hijos',
        'Pareja e Hijos',
        'Solo(a)',
        'Otros',
      ],
      educational_level: [
        'Básica Completa',
        'Media Completa',
        'Técnica Completa',
        'Universitaria Completa',
        'Postgrado o Doctorado Completa',
      ],
      diseases: [
        'Diabetes',
        'HTA',
        'Cardiovascular',
        'Renal',
        'Hepática',
        'Hormonal (Hipotiroidismo y otras)',
        'Otras',
      ],
      diseases_null: [
        'Diabetes',
        'HTA',
        'Cardiovascular',
        'Renal',
        'Hepática',
        'Hormonal (Hipotiroidismo y otras)',
        'Otras',
      ],
      form: {
        marital_status: null,
        living: null,
        educational_level: null,
        job_occupation: '',
        diseases: null,
        specialty: '',
      },
      submitted: false,
      success: false,
      error: '',
    };
  },
  validations: {
    form: {
      marital_status: {
        required,
      },
      living: {
        required,
      },
      educational_level: {
        required,
      },
      job_occupation: {
        required,
      },
      diseases: {
        required,
      },
      specialty: {
        required,
      },
    },
  },
  created() {
    PatientService.getPreForm(this.block.reservation.purchase.user.id)
      .then(response => {
        if (response.data.prediagnostic) {
          this.form = {
            marital_status: response.data.prediagnostic.marital_status,
            living: response.data.prediagnostic.living,
            educational_level: response.data.prediagnostic.educational_level,
            job_occupation: response.data.prediagnostic.job_occupation,
            diseases: response.data.prediagnostic.diseases,
            specialty: response.data.prediagnostic.specialty,
          };
          if (this.form.job_occupation) {
            this.diseases_null = [];
          }
        }
      })
      .catch(err => {
        console.log(`failed to get form: ${err}`);
      });
  },
  methods: {
    handleSubmit() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (this.user.roles.find(e => e.name === 'doctor')) {
        PatientService.savePreForm({
          id: this.block.reservation.purchase.user.id,
          marital_status: this.form.marital_status,
          living: this.form.living,
          educational_level: this.form.educational_level,
          job_occupation: this.form.job_occupation,
          diseases: this.form.diseases,
          specialty: this.form.specialty,
        })
          .then(() => {
            this.success = true;
            setTimeout(() => {
              this.success = false;
            }, 3000);
          })
          .catch(err => {
            console.log(`failed to save form: ${err}`);
            this.error = 'Hubo un error al actualizar los datos.';
            setTimeout(() => {
              this.error = '';
            }, 3000);
          });
      }
    },
  },
  computed: {
    ...mapGetters({
      user: ['authentication/user'],
    }),
  },
};
</script>
