<template>
  <transition name="fade" mode="out-in">
    <div
      v-if="id != null"
      class="flex flex-col items-center justify-end pb-20 text-center w-full col-span-1 md:col-span-10"
    >
      <tabs-component v-model="tab" :tabs="tabs" />
      <ficha-patient
        v-if="tab === 'ficha'"
        :trigger="id"
        :block_prop="block_attribute"
      />
      <datos-patient v-if="tab === 'datos'" :id="id" :block="block_attribute" />
      <consulta-patient
        v-if="tab === 'consulta'"
        :id="id"
        :block="block_attribute"
      />
      <preconsulta-patient
        v-if="tab === 'preconsulta'"
        :id="id"
        :block="block_attribute"
      />
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import Tabs from '@/components/navigation/Tabs';
import Ficha from '@/views/dashboard/user/patient/ficha/Ficha';
import Datos from '@/views/dashboard/user/patient/ficha/Datos';
import Consulta from '@/views/dashboard/user/patient/ficha/Consulta';
import Preconsulta from '@/views/dashboard/user/patient/ficha/Preconsulta';

import PatientService from '@/services/patientService';

export default {
  name: 'SummaryPatient',
  components: {
    'tabs-component': Tabs,
    'ficha-patient': Ficha,
    'datos-patient': Datos,
    'consulta-patient': Consulta,
    'preconsulta-patient': Preconsulta,
  },
  props: {
    id: {
      type: [Number, String],
      default: null,
      required: false,
    },
    block: {
      type: Object,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      tabs: [],
      tab: 'ficha',
      block_attribute: null,
    };
  },
  created() {
    this.block_attribute = this.block;

    if (
      this.user.roles.find(e => e.name === 'doctor') ||
      this.user.roles.find(e => e.name === 'psychologist')
    ) {
      this.tabs.push({
        key: 'ficha',
        label: 'ficha clínica',
      });
      this.tabs.push({
        key: 'datos',
        label: 'datos del paciente',
      });
      this.tabs.push({
        key: 'consulta',
        label: 'primera consulta',
      });
      this.tabs.push({
        key: 'preconsulta',
        label: 'formulario pre consulta',
      });
    }
  },
  methods: {
    calendarReplace() {
      this.toggle();
      this.$router.go(1);
    },
    toggle() {
      this.$emit('toggle', 'ok');
    },
  },
  watch: {
    block(newBlock) {
      this.block_attribute = newBlock;
    },
    tab() {
      PatientService.getPatientFromDoctor(
        this.block_attribute.reservation.purchase.user_id
      )
        .then(response => {
          this.block_attribute.reservation.purchase.user =
            response.data.patient;
        })
        .catch(() => {
          console.log('error getting patient');
        });
    },
  },
  computed: {
    ...mapGetters({
      user: ['authentication/user'],
    }),
  },
};
</script>
