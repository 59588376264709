import { render, staticRenderFns } from "./delete.vue?vue&type=template&id=2fbaf035&scoped=true&"
import script from "./delete.vue?vue&type=script&lang=js&"
export * from "./delete.vue?vue&type=script&lang=js&"
import style0 from "./delete.vue?vue&type=style&index=0&id=2fbaf035&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fbaf035",
  null
  
)

export default component.exports